import FancyButton from "../../../../src/components/fancy-button";
import { faTwitter, faDiscord, faSteam, faYoutube, faReddit, faInstagram, faEthereum, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
export default {
  FancyButton,
  faTwitter,
  faDiscord,
  faSteam,
  faYoutube,
  faReddit,
  faInstagram,
  faEthereum,
  faGithub,
  faPlayCircle,
  React
};